import { Typography, Container, Grid, Button } from "@mui/material";
import MetaData from "../component/Layouts/MetaData/MetaData"
import { Link } from "react-router-dom";



const AboutUs = () => {

  return (
    <>
    </>
  );
};

export default AboutUs;
